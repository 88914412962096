.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.main_loader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: .94;
    overflow: hidden;
    z-index: 99999;
}

.error {
    color: red;
}

.ag-header-cell{
  .ag-react-container{
    overflow: hidden;
    text-overflow: ellipsis;
  }
}


input[type='file'] {
  width:100px;
  color: transparent;
}

.user-dropdown .ant-upload-list-item-name{
    display: none;
}

.user-dropdown .anticon{
    display: none;
}

// .ant-modal-title{
//     text-align: center !important
// }

strong {
    color: #4d4d4d !important;
}

.ag-grid-custom-cell{
    text-align: end;
}

div.ag-grid-cell-error-boundary{
    border-color: rgb(201, 0, 0) !important;
}
div.ag-grid-cell-warning-boundary{
    border-color: #f39200 !important;
}

.ag-grid-clear-cell-error-boundary{
    border-color: green !important;
}

.cursor-pointer{
    cursor: pointer;
    color: #0075FF;
    font-weight: 600;
}

.table-cell-action{
  .cursor-pointer{
    cursor: pointer;
    color: #4697ce;
    padding-right: 5px;
}
}

.ant-modal-body{
    padding: 10px;
}
.ag-body-viewport.ag-layout-auto-height .ag-center-cols-clipper, .ag-body-viewport.ag-layout-auto-height .ag-center-cols-container{
    min-height: 28px;
}

.status-code {
    border-radius: 2px;
    color: #fff;
    background-color: #6a7fb0;
    font-size: 12px;
    padding: 1px 5px;
    &.new {
      background-color: #6a7fb0;
    }
    &.partially {
     &.fulfilled {
      background-color: #ed934e;
     }
    }
    &.label-orange {
      background-color: #ed934e;
    }
    &.awarded {
      background-color: #149e8c !important;
    }
    &.default {
      background-color: #707882 !important;
    }
    // &.pending {
    //   background-color: #92a8a8 !important;
    // }
    &.pending {
      background-color: #92a8a8;
    }
    &.label-primary {
      background-color: #4f69e0;
    }
    &.success {
      background-color: #53bd9b;
    }
    &.rejected {
      background-color: #d64636;
    }
    &.converted {
      background-color: #97a68b;
    }
    &.convertedToQuote
    {
      background-color: #97a68b;
    }
    &.outForPricing {
      background-color: #a0b976;
    }
    &.negotiating {
      background-color: #a3954b;
    }
    &.submitted {
      background-color: #137a58;
    }
    &.accepted {
      background-color: #0f8b3f;
    }
    &.supplierResponded {
      background-color: #2e57a1;
    }
    &.canceled {
      background-color: #b96230;
    }
    &.fulfilled {
      background-color: #004d00;
    }
    &.open{
        background-color: #59868f;
    }
    &.unpaid{
        background-color: #59868f;
    }
    &.paid {
        background-color: #268258;
    }
    &.active {
    background-color: #2f664d;
    }
    &.processed {
    background-color: #387538;
    }
    &.declined {
    background-color: #c93210;
    }
    &.dueDays {
      background-color: #c93210;
      }
    &.shipped {
      background-color: #99b966;
    }
    &.invoiced {
      background-color: #138766;
    }
    &.not {
      background-color: #a7ad88;
    }
    &.delivered {
      background-color: #138766;
    }
  }
  .status-code.partially.fulfilled{ background-color: #ed934e ; }
  .status-code.delivered { background-color: #138766;  }
  .status-code.not{ background-color: #a7ad88; }
  .status-code.invoiced {background-color: #138766; }
  .status-code.shipped{ background-color: #99b966; }
  .status-code.declined {background-color: #c93210; }
  .status-code.processed{background-color: #387538;}
  .status-code.active{background-color: #2f664d; }
  .status-code.paid {background-color: #268258;} 
  .status-code.unpaid{background-color: #59868f;}
  .status-code.open{background-color: #59868f;}
  .status-code.fulfilled{background-color: #004d00;}
  .status-code.canceled{background-color: #b96230; }
  .status-code.supplierResponded{background-color: #2e57a1; }
  .status-code.accepted {background-color: #0f8b3f; }
  .status-code.new {background-color: #6a7fb0;}
  .status-code.label-orange {background-color: #ed934e;}
  .status-code.awarded {background-color: #149e8c !important;}
  .status-code.default {background-color: #707882 !important;}
  .status-code.pending {background-color: #92a8a8; }
  .status-code.label-primary {background-color: #4f69e0;}
  .status-code.success {background-color: #53bd9b;}
  .status-code.rejected {background-color: #d64636;}
  .status-code.converted {background-color: #97a68b;}
  .status-code.convertedToQuote{background-color: #97a68b;}
  .status-code.outForPricing {background-color: #a0b976;}
  .status-code.negotiating {background-color: #a3954b; }
  .status-code.submitted { background-color: #137a58;}

  .ant-input-suffix{
      z-index: 0 !important;
  }

  .anticon{
    z-index: 0 !important;
}

hr{
    height: 1px;
    background-color: #ccc;
    border: none;
}
.align-end {
    text-align: end;
  }

  .fa{
      padding: 2px;
  }

  .ag-row-inline-editing{
    .editable-cell-textarea{
        border: none !important;
    }

  }

  .ag-row-editing.ag-row{
    border-style: none !important;
}

// .show-address-popup{
//     .ag-cell-inline-editing{
//         height: 35px !important;
//     }
// }

.custom-height{
 //   height: 35px !important;
    resize: none;
    width: 100%;
}

.upload-notes{
    color: #39403e;
    font-weight: 400;
    font-size: 11px;
}

.grid-uploader{
  height: auto !important;
}

.custom-readonly-cell{
    pointer-events: none;
    opacity: 0.7;
}

.ant-select-clear{
  margin-top: 0px !important;
}



.ant-spin-nested-loading > div > .ant-spin{
  z-index: 99999999;
}

p{
  padding:  0px!important;
  margin:  0px 0px 0px 0px!important;
}


.ag-theme-balham .ag-cell-description{
  padding-right: 0;
  padding-left: 15px;
}

.ag-cell{
  line-height: 18px !important;
  white-space:  pre-wrap !important;
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

div.custm-ckeditor {
  outline: 0px solid;
  border-bottom: transparent;
}

.description-input {
  resize: none;
  outline: 1px solid #d9d9d9 !important;
  min-height: 100px;
  padding: 10px;
}

.audit-trail-textarea{
  width: 100%;
  border: 1px solid #757575;
  border-bottom:1px solid #757575 !important;
  min-height: 100px;
  border-radius: 2px;;
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
}

span.ant-avatar{
  height: auto !important;
  // width: auto !important;
  max-width: 230px !important;
  max-height: 90px !important;
  // line-height: 100px !important;
}

.description-textarea {
  width: 462px;
  resize: none;
  padding: 5px;
  border: 1px solid #757575 !important;
  min-height: 100px;
  margin-bottom: 10px;
}

.add-new .ant-select-tree-switcher{
  display: none !important;
}

.input-text-error {
  border: 1px solid #ff4d4f !important;

  &:hover {
    border: 1px solid #ff4d4f !important;
  }

  &:focus {
    border: 1px solid #ff4d4f !important;
  }
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode{
  padding: 0px !important;;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper{
  padding: 4px;
}

.delete{
  color: #f44336;
  cursor: pointer;
}

.upoad-file-table table{
  width: auto !important;
}

.pointer-none .ag-react-container{
  pointer-events: none;
}

.complaint-drawer{
  overflow-x: hidden !important;
  overflow-y: auto;
  margin-bottom: 60px;
}
.ant-dropdown{
  z-index: 999999 !important;
}

.action-btn-readonly{
   background-color: #cccfd2 !important;
    box-shadow: 0 2px 2px rgba(0,0,0,.2) !important;
    height: 30px !important;
    padding-top: 5px !important;
    margin-right: 0 !important;
    width: 15px !important;
    border-color: #cccfd2 !important;
    margin-left: 7px !important;
}

.add-footer-textarea {
  //@extend .description-textarea;
  width: 100%;
  border: 1px solid #757575 !important;
  text-align: center;
  padding: 5px;
  margin-bottom: 15px;
}

.cell-wrap-text {
  white-space: normal !important;
}

.extra-width{
  .ant-select-dropdown{
    width: 250px !important;
  }
}

.row-action-dropdown {
  background-color: #ffffff; 
  display: inline-grid;
  max-height: 300px;
  min-width: 200px;
  overflow-y: scroll;
  padding: 5px 6px 5px 13px;


  .ant-dropdown-menu-item {
    display: inline-flex;
    align-items: center;
    border-radius: 4px;


    &:first-child{
      cursor: not-allowed;
      background-color: #ffffff;
      &:hover{
        background-color: #ffffff;
      }

    }
    &:last-child{
      margin-bottom: 5px;
    }

    &:hover {
      background-color: #F2F2F2;
    }
    i{
      margin-right: 7px;
      margin-top: 5px;
    }
  }
}


.create-action-btn {
  background-color: #1cb961;
  border: 1px solid #1cb961;
  padding: 5px 10px;
  border-radius: 2px;
  color: #ffffff;
  height: 38px;
  font-size: 14px;
  cursor: pointer;
}

.view-mode{
  text-wrap: nowrap;
  @media screen and (max-width:480px) {
    display: none;
  }

}

// .view-mode1{
  @media screen and (max-width:480px) {
    .ant-picker-input > input{
      display: none;
      width: 20px;
    }
  }

  .date-view{
    width: 128px;
  }

// }

