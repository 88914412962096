@import '../../assets/stylesheets/global/variable';


%list-flex-row{
    display: flex;
    // flex-direction: row;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
}

%list-flex-col{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    list-style-type: none;
}

.sidebar-tooltip{
    &.ant-tooltip{
        left: 65px !important;

        .ant-tooltip-content{
            .ant-tooltip-inner{
                // background: linear-gradient(9.87deg, #3E2158 -2.75%, #3E2158 -2.74%, #08173D 99.89%);
                background-color: #2F1F51;
                color: #ffffff;
                border-radius: 0 2px 2px 0;
                padding: 6px 12px;

            }
            
            .ant-tooltip-arrow{
                visibility: hidden;
            }
        }
    }
}

.master-menu-expend {
    @media (max-width: 992px) {
        width: 100%; 
        height: 100% !important;
       }  
}

.master-menu{
    z-index: 9999;
    width: $menu-width;
    // background: var(--nav-grd, linear-gradient(147deg, #3E2158 0%, #3E2158 0.01%, #08173D 100%));
    background: linear-gradient(179.87deg, #3E2158 -2.75%, #3E2158 -2.74%, #08173D 99.89%);
    // box-shadow: 0px 26px 14px rgba(0, 170, 0, 0.08);
    color: #ffffff;

    @media (max-width: 992px) {
     width: 100%; 
     height: 72px;
     overflow: hidden;
     background: #0075FF;
    }
    ul{
        height: 100vh;
        overflow-y: auto;
        // margin-left: 4px;

        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        
        /* Hide scrollbar for IE, Edge and Firefox */
        & {
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */
        }



        li{
            // padding: 4px 2px;
            padding-left: 8px;
            padding-right: 8px;
            cursor: pointer;
            list-style-type: none;
            display: flex;
            // align-items: center;
            // justify-content: center;
            margin-top: 0px;
            color: #D8DAFA;
            flex-direction: column;
            font-size: 11px;
            text-align: center;
            // width: 80%;


            // &:first-child{
            //     @extend %list-flex-row;
            //     // border-bottom: 1px solid $header-border-color;
            //     height: $header-height;
            //     position: sticky;
            //     top: 0;
            //     padding: 4px 2px;
            //     cursor: unset;
            //     height: 72px;
            // &:first-child{
            // position: sticky;
            // top: 0;
            // display: flex;
            // align-items: center;
            // justify-content: center;
            // flex-direction: column;
            // height: 68px;
            // padding: 7px 13px;
            // }
             .li-desktop-screen  {
                display: flex;
                @media (max-width: 992px) {
                 display: none;
                }
            }   
            
            .li-mobile-screen  {
                display: none;        
                @media (max-width: 992px) {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 0 15px;
                   }

                  .left-menu  {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .school-name-mobile  {
                        width: 70%;
                    }

                 }

                   .right-menu  {
                    .toggle-button {
                        background-color: transparent;
                        border: none
                    }
                   }
            // }
            
            }

            .name-initial{
                @extend %list-flex-col;
                background-color: #ffffff;
                height: 40px;   
                width: 40px;
                border-radius: 6px;
                color: #5555E3;
                font-weight: 900;
                font-size: 12px;
                letter-spacing: 1px;
                text-transform: capitalize;
                padding: 4px;
                margin-left: -2px;
            }

            // &:nth-child(2){
            //     margin-top: 20px;
            // }

            .master-menu-icon{
                // padding: 4px 14px 8px 2px;
                padding:4px 2px;
             

                i{
                    font-size: 20px;
                    // width: 80%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 12px;
    
                }
                &:hover, &.active{
                    background-color: rgba(255, 255, 255, 0.10);;
                    border-radius: 4px;
                 
                }
            }



            .dot-menu-icon{
                width: 20%;
                margin-top: 5px;

                i{
                    font-size: 14px;
                    &:hover{
                        background-color: transparent;
                        border-radius: none;
                    }
                }
            }
        }
    }

    .menu-list-desktop {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        // li {
        //     &:first-child{
        //     border-bottom: none;
        //     height: 35px;
        //     margin-top: 8px;
            
        //     }
        // }
        @media (max-width: 992px) {
           display: none; 
             }
    }

    .menu-list-mobile {
        display: none;
        @media (max-width: 992px) {
           display: flex; 
           flex-direction: column;
           align-items: flex-start;
           padding-left: 10px;
           height: 100%;
           background-color: #ffffff;
           li {
            color: #071028;
            font-weight: 500;
            // &:first-child{
            // border-bottom: none;
            // height: 35px;
            // margin-top: 20px;
            // color: #071028;
            // font-weight: 500;
            // }
        }

             }
    }
}

.active-menu{
    justify-content: flex-start;
    height: 100vh;
    width: 0px;
    z-index: 9;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: $menu-width;
    bottom: 0px;
    transition: width 0.2s ease-out;
    box-shadow: 0px 26px 50px 0px rgba(0, 0, 0, 0.02);
    border-right: 1px solid $header-border-color;
    @media (max-width: 992px) {
    display: none;
    }

    &.visible{
        width: 218px;
        // transition: width 2s linear;
    }

    .org-name-box{
        height: 68px;
        border-bottom: 1px solid $header-border-color;
        display: flex;
        align-items: center;
        padding: 10px;
        line-height: 1.3;
        color: #58596C;
        font-size: 11px;
    }

    .menu-header{
        text-transform: uppercase;
        letter-spacing: 0.30em;
        padding: 10px 20px;
        align-self: baseline;
        color: #959595;
        font-size: 11px;
        border-bottom: 1px solid $header-border-color;
        height: 68px;
        display: flex;
        align-items: center;

        .menu-closure{
            @extend %list-flex-col;

            right: -14px;
            position: absolute;
            height: 28px;
            width: 28px;
            cursor: pointer;


            border-radius: 1000px;
            border: 1px solid var(--stroek, #F1F1F1);
            background: var(--v-2-white, #FFF);
            box-shadow: 0px 0px 4px 0px rgba(26, 32, 44, 0.12);

            i{
                height: 20px;
                width: 20px;
                // padding: 4px;
                @extend %list-flex-col;
                color: #959595;
                font-weight: 400;

            }
        }

    }

    ul{
        list-style-type: none;

        li{
            padding: 4px 20px;  
            min-height: 44px;        
            .menu-item{
                padding: 5px 0 5px 0;
                // margin-top: 8px;
                display: flex;
                gap:10px;
                cursor: pointer;

                &:hover, &.active{
                    // background-color: rgba(173, 170, 170, 0.5);
                    // border-radius: 4px;
                    color: #5555E3;
                }
            }
        
            &:first-child{
                margin-top: 15px;
            }

            .menu-icon i{
                font-size: 18px;
                @extend %list-flex-row;
                color: #5555E3;
                
            }
            .menu-name{
                width: 70%;
                display: flex;
                align-items: center;
                font-size: 13px;
                padding: 0 5px;

            }
            .right-icon{
                display: flex;
                align-items: center;
                transition: all 0.3s;

                i{
                    font-size: 9px;
                }

                &.trans{
                    transform: rotate(90deg);
                    transition: all 0.3s;
                }

            }
        }
    }

    .menu-childs{
        margin-left: 24px;
        ul{
            max-height: 0px;
            overflow-y: hidden;
            transition: all 0.8s;
        }

        &.open{
            ul{
                max-height: 300px;
                transition: all 0.8s;
            }
        }

        .menu-child-item{
            margin-top: 6px;
            margin-left: 8px;
            padding: 8px 18px;
            min-height: unset;

            &:hover, &.active{
                background-color: #F7F7F7;
                color: #0075FF;
                border-radius: 4px;
                cursor: pointer;
            }
        }

    }

}
.first-child-mm{
    position: sticky;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 68px;
    padding: 7px 13px;
}