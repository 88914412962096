// $fs-11: 11px;

// $green-header: #698c54eb;
// $green-body: #54753feb;

// $darkGray-header: #39516c;
// $darkGray-body: #2b3d51;

// $blue-header: #234f88;
// $blue-body: #063875;

// $orange-header: #88532b;
// $orange-body: #88532b;

// $magenta-header: #6f223c;
// $magenta-body: #6f223c;

// $purple-header: #442452;
// $purple-body: #442452;

// .tiles-wrapper {
//     display: flex;
//     flex-wrap: wrap;

//     .tile-container {
//         background: #dcdcdc;
//         cursor: pointer;
//         width: calc(100%/4 - 7.5px);
//         margin: 0 10px 10px 0;
//         border-radius: 3px;
//         box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);
//         opacity: 0.9;

//         &:hover {
//             opacity: 1;

//             .tile-body {
//                 i{
//                     color: #fff;
//                 }
//             }
//         }

//         &:last-child {
//             margin-right: 0;
//         }

//         .tile-header {
//             font-size: $fs-11;
//             background: #999;
//             padding: 6px 10px;
//             color: #fff;
//             text-transform: uppercase;
//             border-radius: 2px;
//         }

//         .tile-body {
//             padding: 10px;
//             border-radius: 2px;

//             i {
//                 font-size: 32px;
//                 color: rgba(255, 255, 255, .6);

//                 * {
//                     font-size: 32px;
//                 }
//             }

//             &.tile-count-info {
//                 display: flex;
//                 height: 65px;
//                 justify-content: space-between;

//                 .count-value {
//                     display: inline-flex;
//                     flex-direction: column;
//                 }
//             }

//             .tile-count {
//                 margin-left: auto;
//                 font-size: 11px;
//                 background: #0000004d;
//                 padding: 1px 6px;
//                 border-radius: 10px;
//                 color: #fff;
//                 float: right;

//                 &::after {
//                     clear: both;
//                     content: ' ';
//                 }
//             }
//         }

//         .tile-footer {
//             font-size: $fs-11;
//             padding: 6px 10px;
//             text-align: right;
//             color: #fff;
//             text-transform: uppercase;
//             border-radius: 2px;
//         }
//     }


//     .tile-container.default {
//         background: #dcdcdc;

//         .tile-header,
//         .tile-footer {
//             background: #999;
//         }
//     }

//     .tile-container.green-tile {
//         background: $green-body;

//         .tile-header,
//         .tile-footer {
//             background: $green-header;
//         }
//     }

//     .tile-container.darkGray-tile {
//         background: $darkGray-body;

//         .tile-header,
//         .tile-footer {
//             background: $darkGray-header;
//         }
//     }

//     .tile-container.blue-tile {
//         background: $blue-body;

//         .tile-header,
//         .tile-footer {
//             background: $blue-header;
//         }
//     }

//     .tile-container.orange-tile {
//         background: $orange-body;

//         .tile-header,
//         .tile-footer {
//             background: $orange-header;
//         }
//     }

//     .tile-container.magenta-tile {
//         background: $magenta-body;

//         .tile-header,
//         .tile-footer {
//             background: $magenta-header;
//         }
//     }

//     .tile-container.purple-tile {
//         background: $purple-body;

//         .tile-header,
//         .tile-footer {
//             background: $purple-header;
//         }
//     }
// }
$fs-11: 11px;

$green-header: #698c54eb;
$green-body: #54753feb;

$darkGray-header: #39516c;
$darkGray-body: #2b3d51;

$blue-header: #234f88;
$blue-body: #063875;

$orange-header: #88532b;
$orange-body: #88532b;

$magenta-header: #6f223c;
$magenta-body: #6f223c;

$purple-header: #442452;
$purple-body: #442452;

// $lightBlue-body: #dad8dc;
// $lightRed-body: #ffcd58dc;
// $lightOrange-body: #ff9736dc;
// $lightGreen-body: #cc7338dc;

$lightBlue-body: #ffffff;
$lightRed-body: #ffffff;
$lightOrange-body: #ffffff;
$lightGreen-body: #ffffff;

.tiles-wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: #F6F8FF;

    .tile-container {
        background: #ffffff;
        cursor: pointer;
        width: calc(100%/4 - 7.5px);
        margin: 0 10px 10px 0;
        border-radius: 4px;

        // border-radius: 0px, 10px, 0px, 10px;
        // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

        &:hover {

            .tile-body {
                i{
                    // color: #fff;
                }
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body {
            padding: 10px;
            height: 124px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value {
                display: inline-flex;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
        }

        .tile-footer {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}


.tiles-wrapper-dashboard {
    display: flex;
    // flex-wrap: wrap;
    padding: 20px 20px 21px 20px;
    gap: 10px;

    .tile-container-dashboard {
        background: #ffffff;
        cursor: pointer;
        border-radius: 4px;
        height: 90px;
        flex-basis: calc( 100% / 4);

        &.tile1{
          border-bottom:2px solid #BF4CC8;
        }
        &.tile2{
          border-bottom:2px solid #0075FF;
        }
        &.tile3{
          border-bottom:2px solid #F85353;
        }
        &.tile4{
          border-bottom:2px solid #1CB980;
        }
        &.tile5{
          border-bottom:2px solid #0075FF;
        }
        &.tile6{
          border-bottom:2px solid #F6773D;
        }

        &:last-child {
            margin-right: 0;
        }

        .tile-header-dashboard {
            font-size: $fs-11;
            background: #999;
            padding: 6px 10px;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }

        .tile-body-dashboard {
            padding: 10px;
            height: 90px;
            display: flex;
            // flex-direction: column;
            justify-content: space-between;

            @media screen and (max-width:480px) {
            width: 135px;
            }
          .left-tile-body-dashboard{
            padding: 20px 0px;
            display: flex;
            flex-direction: column;
          }
          .tile-name-dashboard{
            font-size: 10px;
          }
          .total-fee{
            text-wrap: nowrap;
          }
            .ant-icons{
                font-size: 24px;
                line-height: 30px;
            }
            .tile-name{
                font-size: 12px;
            }

            // i {
            //     // font-size: 24px;
            //     // color: rgba(255, 255, 255, .6);

            //     * {
            //         font-size: 32px;
            //     }
            // }
            .count-value-dashboard {
                display: inline-flex;
                // padding: 20px 0px;
                padding-top:11px ;
                // flex-direction: column;
                
            
                :last-child{
                    margin-left: auto;
                    margin-top: auto;

                    .ant-btn{
                        background-color: #ecf5ff;
                        border-color: #ecf5ff;
                        color:#0C1636;
                        font-size: small;
                        padding: 5px 8px;

                        :active{
                            background-color: #ecf5ff;
                            border-color: #ecf5ff;
                        }
                        :hover{
                            opacity: 0.5;
                        }
                    }
                }
            // &.tile-count-info {
            //     display: flex;
            //     height: 124px;
            //     flex-direction: column;
            //     justify-content: space-between;
                
            // }

                .tile-count-dashboard {
                    font-size: 14px;
                    font-weight: 700;
                    // padding: 0px;
                    border-radius: 10px;
                    color:#0C1636;
                   
 

                    // &::after {
                    //     clear: both;
                    //     content: ' ';
                    // }

                    .icon-currency-dashboard{
                        .fa{
                            font-size: 12px;
                            padding-right: 3px;
                            font-weight: 700;
                            color:#0C1636 !important;
                        }
                    }
                }
            }
            
        }

        .tile-footer-dashboard {
            font-size: $fs-11;
            padding: 6px 10px;
            text-align: right;
            color: #fff;
            text-transform: uppercase;
            border-radius: 2px;
        }
    }


    // .tile-container.default {
    //     background: #dcdcdc;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: #999;
    //     }
    // }

    // .tile-container.green-tile {
    //     background: $lightRed-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $green-header;
    //     }
    // }

    // .tile-container.darkGray-tile {
    //     background: $lightBlue-body;
    //     border-radius: 4px;

    //     .tile-header,
    //     .tile-footer {
    //         background: $darkGray-header;
    //     }
    // }

    // .tile-container.blue-tile {
    //     background: $lightOrange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $blue-header;
    //     }
    // }

    // .tile-container.orange-tile {
    //     background: $orange-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $orange-header;
    //     }
    // }

    // .tile-container.magenta-tile {
    //     background: $lightGreen-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $magenta-header;
    //     }
    // }

    // .tile-container.purple-tile {
    //     background: $purple-body;
    //     border-radius: 4px;
    //     .tile-header,
    //     .tile-footer {
    //         background: $purple-header;
    //     }
    // }
}

// @media only screen and (max-width: 1440px){
//     .tiles-wrapper-dashboard {
//       display: flex;
//       flex-wrap: wrap;
//     //   margin: 20px 20px 0 24px;
//         gap: 15px; 
  
//       .tile-container-dashboard {
//           background: #ffffff;
//           cursor: pointer;
//           width: 16%;
//         //   margin: 0 10px 10px 0;
//           border-radius: 4px;
//           height: 90px;
//           // padding-left: 8px;
//           // padding-right: 8px;
//           flex-grow: inherit;
//           flex-basis: 321px;
  
//           // border-radius: 0px, 10px, 0px, 10px;
//           // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);
  
//           &:hover {
  
//               .tile-body-dashboard {
//                   i{
//                       // color: #fff;
//                   }
//               }
//           }
  
//           &:last-child {
//               margin-right: 0;
//           }
  
//           .tile-header-dashboard {
//               // font-size: $fs-11;
//               background: #999;
//               padding: 6px 10px;
//               color: #fff;
//               text-transform: uppercase;
//               border-radius: 2px;
//           }
  
//           .tile-body-dashboard {
//               padding: 10px;
//               height: 90px;
//               display: flex;
//               // flex-direction: column;
//               justify-content: space-between;
//             .left-tile-body-dashboard{
//               padding: 20px 0px;
//               display: flex;
//               flex-direction: column;
//             }
//             .tile-name-dashboard{
//               font-size: 10px;
//             }
//               .ant-icons{
//                   font-size: 24px;
//                   line-height: 30px;
//               }
//               .tile-name{
//                   font-size: 12px;
//               }
  
//               // i {
//               //     // font-size: 24px;
//               //     // color: rgba(255, 255, 255, .6);
  
//               //     * {
//               //         font-size: 32px;
//               //     }
//               // }
//               .count-value-dashboard {
//                   display: inline-flex;
//                   // padding: 20px 0px;
//                   padding-top:11px ;
//                   // flex-direction: column;
                  
              
//                   :last-child{
//                       margin-left: auto;
//                       margin-top: auto;
  
//                       .ant-btn{
//                           background-color: #ecf5ff;
//                           border-color: #ecf5ff;
//                           color:#0C1636;
//                           font-size: small;
//                           padding: 5px 8px;
  
//                           :active{
//                               background-color: #ecf5ff;
//                               border-color: #ecf5ff;
//                           }
//                           :hover{
//                               opacity: 0.5;
//                           }
//                       }
//                   }
//               // &.tile-count-info {
//               //     display: flex;
//               //     height: 124px;
//               //     flex-direction: column;
//               //     justify-content: space-between;
                  
//               // }
  
//                   .tile-count-dashboard {
//                       font-size: 14px;
//                       font-weight: 700;
//                       // padding: 0px;
//                       border-radius: 10px;
//                       color:#0C1636;
                     
   
  
//                       // &::after {
//                       //     clear: both;
//                       //     content: ' ';
//                       // }
  
//                       .icon-currency-dashboard{
//                           .fa{
//                               font-size: 12px;
//                               padding-right: 3px;
//                               font-weight: 700;
//                               color:#0C1636 !important;
//                           }
//                       }
//                   }
//               }
              
//           }
  
//           .tile-footer-dashboard {
//               // font-size: $fs-11;
//               padding: 6px 10px;
//               text-align: right;
//               color: #fff;
//               text-transform: uppercase;
//               border-radius: 2px;
//           }
//       }
  
  
//       // .tile-container.default {
//       //     background: #dcdcdc;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: #999;
//       //     }
//       // }
  
//       // .tile-container.green-tile {
//       //     background: $lightRed-body;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $green-header;
//       //     }
//       // }
  
//       // .tile-container.darkGray-tile {
//       //     background: $lightBlue-body;
//       //     border-radius: 4px;
  
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $darkGray-header;
//       //     }
//       // }
  
//       // .tile-container.blue-tile {
//       //     background: $lightOrange-body;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $blue-header;
//       //     }
//       // }
  
//       // .tile-container.orange-tile {
//       //     background: $orange-body;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $orange-header;
//       //     }
//       // }
  
//       // .tile-container.magenta-tile {
//       //     background: $lightGreen-body;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $magenta-header;
//       //     }
//       // }
  
//       // .tile-container.purple-tile {
//       //     background: $purple-body;
//       //     border-radius: 4px;
//       //     .tile-header,
//       //     .tile-footer {
//       //         background: $purple-header;
//       //     }
//       // }
//   }
//   }

// @media only screen and (min-width: 1920px){
//   .tiles-wrapper-dashboard {
//     display: flex;
//     flex-wrap: wrap;
//     margin: 20px 20px 0 24px;

//     .tile-container-dashboard {
//         background: #ffffff;
//         cursor: pointer;
//         width: 16%;
//         margin: 0 10px 10px 0;
//         border-radius: 4px;
//         height: 90px;
//         // padding-left: 8px;
//         // padding-right: 8px;
//         flex-grow: inherit;
//         flex-basis: 459px;

//         // border-radius: 0px, 10px, 0px, 10px;
//         // box-shadow: 0 10px 10px rgba(0, 0, 0, 0.22), 0 0 8px rgba(0, 0, 0, 0.10);

//         &:hover {

//             .tile-body-dashboard {
//                 i{
//                     // color: #fff;
//                 }
//             }
//         }

//         &:last-child {
//             margin-right: 0;
//         }

//         .tile-header-dashboard {
//             // font-size: $fs-11;
//             background: #999;
//             padding: 6px 10px;
//             color: #fff;
//             text-transform: uppercase;
//             border-radius: 2px;
//         }

//         .tile-body-dashboard {
//             padding: 10px;
//             height: 90px;
//             display: flex;
//             // flex-direction: column;
//             justify-content: space-between;
//           .left-tile-body-dashboard{
//             padding: 20px 0px;
//             display: flex;
//             flex-direction: column;
//           }
//           .tile-name-dashboard{
//             font-size: 10px;
//           }
//             .ant-icons{
//                 font-size: 24px;
//                 line-height: 30px;
//             }
//             .tile-name{
//                 font-size: 12px;
//             }

//             // i {
//             //     // font-size: 24px;
//             //     // color: rgba(255, 255, 255, .6);

//             //     * {
//             //         font-size: 32px;
//             //     }
//             // }
//             .count-value-dashboard {
//                 display: inline-flex;
//                 // padding: 20px 0px;
//                 padding-top:11px ;
//                 // flex-direction: column;
                
            
//                 :last-child{
//                     margin-left: auto;
//                     margin-top: auto;

//                     .ant-btn{
//                         background-color: #ecf5ff;
//                         border-color: #ecf5ff;
//                         color:#0C1636;
//                         font-size: small;
//                         padding: 5px 8px;

//                         :active{
//                             background-color: #ecf5ff;
//                             border-color: #ecf5ff;
//                         }
//                         :hover{
//                             opacity: 0.5;
//                         }
//                     }
//                 }
//             // &.tile-count-info {
//             //     display: flex;
//             //     height: 124px;
//             //     flex-direction: column;
//             //     justify-content: space-between;
                
//             // }

//                 .tile-count-dashboard {
//                     font-size: 14px;
//                     font-weight: 700;
//                     // padding: 0px;
//                     border-radius: 10px;
//                     color:#0C1636;
                   
 

//                     // &::after {
//                     //     clear: both;
//                     //     content: ' ';
//                     // }

//                     .icon-currency-dashboard{
//                         .fa{
//                             font-size: 12px;
//                             padding-right: 3px;
//                             font-weight: 700;
//                             color:#0C1636 !important;
//                         }
//                     }
//                 }
//             }
            
//         }

//         .tile-footer-dashboard {
//             // font-size: $fs-11;
//             padding: 6px 10px;
//             text-align: right;
//             color: #fff;
//             text-transform: uppercase;
//             border-radius: 2px;
//         }
//     }


//     // .tile-container.default {
//     //     background: #dcdcdc;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: #999;
//     //     }
//     // }

//     // .tile-container.green-tile {
//     //     background: $lightRed-body;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $green-header;
//     //     }
//     // }

//     // .tile-container.darkGray-tile {
//     //     background: $lightBlue-body;
//     //     border-radius: 4px;

//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $darkGray-header;
//     //     }
//     // }

//     // .tile-container.blue-tile {
//     //     background: $lightOrange-body;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $blue-header;
//     //     }
//     // }

//     // .tile-container.orange-tile {
//     //     background: $orange-body;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $orange-header;
//     //     }
//     // }

//     // .tile-container.magenta-tile {
//     //     background: $lightGreen-body;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $magenta-header;
//     //     }
//     // }

//     // .tile-container.purple-tile {
//     //     background: $purple-body;
//     //     border-radius: 4px;
//     //     .tile-header,
//     //     .tile-footer {
//     //         background: $purple-header;
//     //     }
//     // }
// }
// }