@import '../common.scss';

.forgot-wrapper {
    background: $body-bg;
    min-height: 100vh;

    .forgot-panel {
        background-color: $white;
        padding: 15px;
    }

    h4 {
        font-size: 18px;
        margin-bottom: 25px;
    }

    .forgot-lock-icon {
        background: $grey-color;
        border-radius: 3px 0px 0px 3px;
        border: 1px solid $grey-color;
        // padding: 5.5px;
        display:flex ;
        align-items: center;
        justify-content: center;
    }

    .forgot-form-input {
        border-radius: 0px 3px 3px 0px;
    }

    .forgot-form-input:hover {
        border-color: $green-btn-border;
    }

    .forgot-form-input:focus {
        border-color: $green-btn-border;
    }

    .input-error {
        color: $error-red;
        border: 1px solid $error-red;
        border-radius: 0px 3px 3px 0px;
    }

    .forgot-form-input-error {
        color: $error-red;
        border-radius: 0px 3px 3px 0px;
    }

    .forgot-form-input-error:hover {
        border: 1px solid $error-red;
    }

    .forgot-form-input-error:focus {
        border: 1px solid $error-red;
    }

    .forgot-goback {
        @include default-btn-style;
    }

    .forgot-goback:hover {
        background-color: #cbd2db;
    }

    // .forgot-send-email {
    //     @include green-btn-style;
    // }

    // .forgot-send-email:hover {
    //     background-color: #357360;
    // }

    .activate-send-email {
        // @include green-btn-style;
        background-color: #0075FF !important;
        border-color: #0075FF; 
        width: 100% !important;
        height: 48px !important; 
        border-radius: 4px;
        // margin-top: 30px;
        margin-bottom: 12px;
    }
    .activate-send-email:hover {
        background-color: #0075FF !important;
        opacity: 0.8;
    }

    .bert {
        width: 100%;
        text-align: center;
    }
}