.input-text-error {
  border: 1px solid $error-red;
  height: 44px;

  &:hover {
    border: 1px solid $error-red;
  }

  &:focus {
    border: 1px solid $error-red;
  }
}

.input-text {
  color: $green-btn-border;
  height: 38px;

  &:hover {
    border: 1px solid $green-btn-border;
  }

  &:focus {
    border: 1px solid $green-btn-border;
  }
}


.ant-btn-primary {
  height: 38px;
  background-color: #0075FF;
  border-color: none ;
  color: white ;
  border-radius: 4px;

  &:focus{
    height: 38px;
    background-color: #0075FF !important;
    // border-color: #0075FF !important ;
    color: white !important;
  }
}


.ant-btn{
  height: 38px;
  border-radius: 4px;
}