@import "./../assets/stylesheets/global/variable";
.horizontal-seperator {
    margin: 15px 0;
    border-bottom: 1px solid #e9ecf0;
}

.fw-600 {
    font-weight: 600;
}

@mixin green-btn-style {
    color: $white;
    background-color: $green-btn-bg;
    border-color: $green-btn-border;
}

@mixin default-btn-style {
    color: #656b79;
    background-color: #e9ecf0;
    border-color: #e9ecf0;
}

@mixin green-border {
    border: 1px solid $green-btn-border;
}

@mixin white-wrapper {
    background: $white;
    border-radius: 3px;
}

h4 {
    font-size: $fs-18;
}

.border-red {
    border: 1px solid $error-red;
}

.hide {
    display: none;
}

.link-color {
    color: #4697ce;

    &:hover {
        cursor: pointer;
        text-decoration: underline;
    }
}

.pull-right{
    float: right;
}
.ant-modal-footer{
    justify-content: space-between;
      display: flex;
  }

  .space-holder{
    height: 20px;
  }
  .link-for-form{
    cursor: pointer;
    color: #4697ce;
    font-size: 12px;
   
  }
  .essential{
    color: var(--Green, #1CB980);
  }