.ant-table-container {
  .ant-table-content {
      table {
        .ant-table-tbody {
          .ant-table-cell {
            border-right: 1px solid #f0f0f0;
            padding-top: 4px;
            padding-bottom: 5px;
          }

          .ant-table-row {
            td {
              &:first-child {
                border-left: 1px solid #f0f0f0;
              }
            }
          }
        }
      }
  }
}
