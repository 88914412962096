@import '~antd/dist/antd.css';
@import '../common.scss';
// @import '../../../public/sass/variable';
@import '../../assets/stylesheets/global/variable';

%list-flex {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.header-wrapper {
  @extend %list-flex;
  background: $header-color;
  height: $header-height;
  color: $header-text;
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: calc(100% - #{$menu-width});
  z-index: 9;
  border-bottom: 1px solid $header-border-color;
  padding: 20px;
  // box-shadow: 26px 0px 6px 0px rgba(0, 0, 0, 0.06);


  i {
    cursor: pointer;
    color: inherit;
  }

  // .header-option {
  //   border-left: 1px solid $header-border-color;
  //   color: $header-text;
  //   line-height: 20px;
  //   list-style-type: none;
  //   padding: 10px 16px;

  //   &:hover,
  //   &:active,
  //   &:focus {
  //     color: $header-hover-text;
  //     cursor: pointer;
  //   }
  // }

  .header-left-section {
    width: 50%;

    
    .left-head{
      color: #071028;
      display: flex;
      font-size: 16px;
      font-weight: 600;
      padding: 0px 0px 8px 6px;

    }

    ul{
      @extend %list-flex;
      justify-content: center;
      gap: 10px;
    }
  }
  .header-right-section{
    width: 50%;

    ul{
      // @extend %list-flex;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 20px;
      align-items: center;
      margin: 0;

      li{

        &.header-option {
          position: relative;
          // width: 170px;
          width: 70px;
        background-color: unset;
        cursor: pointer;

          .user-icon{
            width: 40px;
            height: 40px;
            background-color: #FCDF4C;
            color: #000000;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            // letter-spacing: 1px;
            cursor: pointer;
            padding: 3px 0 0 3px;
            font-size: 12px;
          }
          .user-icon-dropdown{
            width: 50px;
            height: 50px;
            background-color: #FCDF4C;
            color: #000000;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            letter-spacing: 1px;
            cursor: pointer;
            padding: 3px 0 0 3px;
            font-size: 12px;
          }
          .user-detail{
            margin-left: 10px;
            color: #071028;
            width: 90px;
            display: flex;
            // align-items: center;
            justify-content: center;
            flex-direction: column;
            line-height: 1.3;
  
            .role{
              color: #071028;
              font-size: 11px;
              opacity: 50%;
              letter-spacing: 1px;
            }
          }
          .right-dropdown{
            display: flex;
            align-items: center;
            margin-left: 10px;
  
            i{
              color: #FFA1AC;
              // opacity: 50%;
              margin-top: 4px;
            }
          }
      
          .user-dropdown {
            border: 1px solid #f0f0f1;
            border-radius: 4px;
            display: none;
            width: 360px;
      
            .user-header {
              padding: 20px 15px 10px 15px;
              position: relative;
              // display: flex;
              flex-direction: row;
              font-size: 12px;
              border-bottom: 1px solid #e7e7e7;
              img{
                height: 60px;
            width: 60px;
            overflow: hidden;
            // border-radius: 50%;
            margin-bottom: 0px;
            background: #ededed;
            background-color: #ffffff;
              }
              .user-header-heading{
                display: flex;
                // justify-content: space-between;
                gap: 15px
              }
              .user-info{
                
                display: flex;
                flex-direction: column;
               gap: 6px;
.ant-divider-horizontal{
  margin: 8px 2px 8px 0px;
}
                .user-info-line{
                  font-size: 13px;
                  font-weight: 500;
                  height: 30px;
                  padding-left:14px;
            
                  cursor: pointer;

                  i{
                    padding-right: 16px;
                  font-size: 20px;
                  }
                }
                .user-details{
                  line-height: 22px;
                  .link{
                    color:#478BCA;
                    font-size: 12px;
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
              .close {
                cursor: pointer;
                font-size: 20px;
                opacity: .7;
                position: absolute;
                top: 15px;
                right: 15px;
      
                &:hover {
                  opacity: 1;
                }
              }
      
              .my-account-logout {
                .link {
                  color: #478BCA;
                  cursor: pointer;
                  position: relative;
      
                  &:after {
                    content: '';
                    position: absolute;
                    top: 3px;
                    right: -20px;
                    background-color: #656b79;
                    width: 1px;
                    height: 15px;
                  }
                }
      
                .logout {
                  color: #E88F83;
                  cursor: pointer;
                }
              }
            }
      
            .signout-footer{
              padding: 10px;
              .ant-btn{
                width: 100%;
                height: 38px;
                font-size: 14px;
              }
            }
            
      
            .user-footer {
              border-top: 1px solid #d8dcde;
              padding: 10px;
              text-align: center;
      
              ul {
                color: #656b79;
                display: flex;
                justify-content: center;
      
                li {
                  cursor: pointer;
                  list-style-type: disc;
                  margin-right: 15px;
                  padding-right: 15px;
                  padding-left: 15px;
      
                  &:first-child {
                    list-style-type: none;
                  }
      
                  &:last-child {
                    margin-right: 0;
                    padding-left: 0;
                  }
                }
              }
            }
          }
      
          &.active {
            .user-dropdown {
              display: inline-block;
              background-color: #fff;
              border-radius: 4px;
              box-shadow: 0 0 8px rgba(148, 147, 147, 0.5);
              cursor: default;
              position: absolute;
              top: 50px;
              right: 8px;
              width: 250px;
      
              &:after {
                content: '';
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-bottom: 5px solid #fff;
                position: absolute;
                top: -5px;
                right: 5px;
              }
            }
          }
        }

        select{
          width: 250px;
          height: 38px;
          border: 1px solid rgb(202, 201, 201);
          background-color: #fff;
          padding: 5px 8px;
          color: #656b79;
          border-radius: 2px;
        }
        
      }

    }

  }
}

.top-layer{
  height: 100vh;
  position: absolute;
  width: 100%;
  background-color: rgba(85, 85, 227, 0.54);
  z-index: 9999999999999;
  
  .info-container{
    position: relative;
    top: calc(50% - 200px);
    left: calc(50% - 325px);
    width: 700px;
    height: 400px;
    background-color: #ffffff;
    opacity: 1;
    display: flex;
    flex-direction: row;

    .left{
      width: 35%;
      height: 100%;
      padding: 10px;

      display: flex;
      align-items: center;
      justify-content: center;

    }
    .right{
      width: 60%;
      height: 100%;
      padding: 10px;

      .close{
        position: relative;
        right: -410px;
        top: 5px;
        cursor: pointer;
        height: 0px;
      }
      
      .header{
        font-size: 16px;
        font-weight: 700;
        color: rgba(0, 117, 255, 1);
        padding: 0 10px 10px 10px;
        width: calc(100% - 16px);
        margin-top: -10px;
        // margin-top: 10px;
        // text-align: center;
      }

      .body{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0px 10px;
        line-height: 1.3em;
        font-size: 12px;
        // margin-top: 10px;

        .form-list{
          max-height: 70px;
          overflow-y: auto;
          font-weight: 700;
          min-height: 30px;
          line-height: 1.5em;
        }

        .ant-btn-primary{
          margin: 10px 0px;
          background-color:rgba(28, 185, 128, 1);
          border-color: rgba(28, 185, 128, 1);
          &:focus{
            background-color:rgba(28, 185, 128, 1);
            border-color: rgba(28, 185, 128, 1);
          }
        }

      }
    }
  }
}

.name-heading{
  font-weight:700;
  text-transform: capitalize;
}


// .header-right-functions {
//   .header-option {
//     position: relative;

//     .user-dropdown {
//       border: 1px solid #f0f0f1;
//       border-radius: 4px;
//       display: none;
//       width: 360px;

//       .user-header {
//         background-color: #F3F8FE;
//         color: #272727;
//         padding-top: 30px;
//         padding-bottom: 30px;
//         position: relative;
//         text-align: center;

//         .close {
//           cursor: pointer;
//           font-size: 20px;
//           opacity: .7;
//           position: absolute;
//           top: 15px;
//           right: 15px;

//           &:hover {
//             opacity: 1;
//           }
//         }

//         .my-account-logout {
//           .link {
//             color: #478BCA;
//             cursor: pointer;
//             position: relative;

//             &:after {
//               content: '';
//               position: absolute;
//               top: 3px;
//               right: -20px;
//               background-color: #656b79;
//               width: 1px;
//               height: 15px;
//             }
//           }

//           .logout {
//             color: #E88F83;
//             cursor: pointer;
//           }
//         }
//       }

//       .organisation-info {
//         background-color: #fff;
//         color: #656b79;
//         font-size: 13px;

//         .title {
//           text-transform: capitalize;
//         }

//         .link {
//           color: #478BCA;
//         }
//       }

//       .user-footer {
//         border-top: 1px solid #d8dcde;
//         padding: 10px;
//         text-align: center;

//         ul {
//           color: #656b79;
//           display: flex;
//           justify-content: center;

//           li {
//             cursor: pointer;
//             list-style-type: disc;
//             margin-right: 15px;
//             padding-right: 15px;
//             padding-left: 15px;

//             &:first-child {
//               list-style-type: none;
//             }

//             &:last-child {
//               margin-right: 0;
//               padding-left: 0;
//             }
//           }
//         }
//       }
//     }

//     &.active {
//       .user-dropdown {
//         display: inline-block;
//         background-color: #fff;
//         border-radius: 4px;
//         box-shadow: 0 2px 18px rgba(0, 0, 0, .5);
//         cursor: default;
//         position: absolute;
//         top: 37px;
//         right: 20px;

//         &:after {
//           content: '';
//           width: 0;
//           height: 0;
//           border-left: 5px solid transparent;
//           border-right: 5px solid transparent;
//           border-bottom: 5px solid #fff;
//           position: absolute;
//           top: -5px;
//           right: 5px;
//         }
//       }
//     }
//   }
// }


