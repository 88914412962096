$p-15 : 15px;
$fs-18 : 18px;
$grey-color: #e7e7e7;
$green-btn-bg: #4ca185;
$green-btn-border: #51a35d;
$white: #fff;
$error-red: #ff4d4f;
$body-bg: linear-gradient(to right, #2c3e52, #7f7f7f);
 
// header part
// $header-border-color: #D8DAFA;
// $header-border-color: rgba(216, 218, 250, 0.20000000298023224);
$header-border-color: #F1F1F1;


$header-height: 68px;
// $menu-width: 76px;
$menu-width: 92px;
$header-color: #ffffff;
$header-text: #4e4e4e;
$header-hover-text: gray;

$primaryColor: #0075FF;



// colors used in whole project
$primaryColor: #08173d;
$secondaryColor: #0075FF;
$linkColor: #0075FF;