.modal-custom-detail {
  overflow: inherit;
}

.modal-custom-detail .ant-modal-content {
  min-height: 100vh;
}


.custom-modal-content {
  .ant-modal-content {
    min-height: 550px;
    max-height: 550px;
    overflow-y: auto;
  }
}

.custom-modal-full-height-content {
  .ant-modal-content {
    min-height: 800px;
    max-height: 800px;
    overflow-y: auto;
  }
}

.customModal-button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.customModal-button-group-center {
  display: flex;
  justify-content: center;
}

.balance-sheet-popup-container {
  height: 400px;
  overflow: scroll;
}

.ant-modal-body {
  .agGridWrapper {
    height: 400px;
    overflow: scroll;
  }
}

.balance-sheet-table {
  border-collapse: collapse;
  color: #656b79;
  font-family: "Open Sans", "Segoe UI", "Droid Sans", Tahoma, Arial, sans-serif;
  font-size: 13px;
  width: 100%;

  th,
  td {
    border: 1px solid #f5f5f5;
    text-align: left;
    padding: 6px 10px;
  }


  tr {
    &:nth-child(even) {
      background-color: #f9f9f9;
    }

    &:nth-child(odd) {
      background-color: #fbfbfb;
    }
  }

  .bold-text {
    font-weight: 700;
  }

  .text-center {
    text-align: center;
  }

  .text-right {
    text-align: right;
  }

  .white-bg {
    background: #fff;
    border-right: 0;
  }
}


.modal-custom-registration .ant-modal-content .ant-modal-body {
  max-height: initial;
}

.conts-scrolls {
  max-height: 510px;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 30px;

  .ant-select-arrow {
    // margin-top: 0px;
    // top: 56%
  }
}


.drop-arrow-up{
    .ant-select-arrow {
    margin-top: 0px;
    top: 56%
  }
}

.pro-address{
  max-height: 410px;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 30px;
  .ant-legacy-form-item{
    margin-bottom: 0px;
  }
  .ant-select-arrow {
  margin-top: 0px;
  top: 56%
}
}

.phone-city{
  display: flex;
  .ant-select{ width: 40%; 
  margin-right: 10px;
  }

  
}

.por-pics{

  .upload-profile-pic{
    
    margin-left: 90%;

    img{ height: 80px !important;
      width: 80px !important;
     text-align: center;
     border-radius: 50%;
     margin-left: auto;
     margin-right: auto;
    }
  }
}


.emapl-address{
  max-height: 500px;
  overflow: auto;
  padding-right: 10px;
  padding-bottom: 30px;
  .ant-legacy-form-item{
    margin-bottom: 0px;
  }
  .ant-select-arrow {
  margin-top: 0px;
  top: 56%
}
}

.modal-invoice-detail {
  overflow-y: auto;
}

.modal-invoice-detail .ant-modal-content {
  min-height: 90vh;
}