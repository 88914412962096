.custom-tabs-components {
  position: relative;

  .ant-tabs-nav-container {
    .ant-tabs-nav {
      .ant-tabs-tab {
        background-color: rgba(255,255,255,0);
        border-color: #16a085;
        border-width: 1px;
        border-style: solid;
        border-radius: 3px;
        color: #333;
        height: 30px;
        line-height: 12px;
        margin-right: 5px;
        margin-top: 10px;
        margin-bottom: 10px;

        &.ant-tabs-tab-active {
          background-color: #16a085;
          color: #fff ;
        }
      }
    }
  }

  .ant-tabs-bar {
    border-bottom: 0!important;
  }
}

.primary-tab-container {
  .ant-tabs-bar {
    border-bottom: 0;
  }

  .ant-tabs-nav {
    border-bottom: 1px solid #e8e8e8;

    .ant-tabs-tab {
      // border-bottom-color: transparent!important;
      padding: 2px 25px!important;

      &.ant-tabs-tab-active {
        border-color: transparent!important;
        z-index: 90;

        span {
          color: #2c7045;
        }

        &:before {
          background-color: #2c7045;
          z-index: 99;
        }
      }

      &:before {
        border-radius: 0!important;
        top: unset!important;
        bottom: -1px!important;
        // left: -1px!important;
        height: 3px;
        width: 100%;
      }
    }
  }

  &.secondary-tab {
    .ant-tabs-bar {
      margin-bottom: 2px;
    }

    // &:before {
    //   border-radius: 0;
    //  top: unset!important;
    //   bottom: 0px!important;
    //   left: 0px!important;
    //   height: 0px;
    //   width: 100%;
    // }
    .ant-tabs-nav-wrap {
      background-color: #fff;
      border-radius: 6px;
      box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.05);
      padding-right: 10px;
      padding-left: 10px;
    }

    .ant-tabs-nav {
      border-bottom: transparent;

      .ant-tabs-tab {
        // background: #16a085;
        // border-color: transparent!important;
        padding: 6px 20px!important;  border-radius: 3px; color: #333;  margin: 0px 3px;
      }
     
    }
  }

  &.manage-bottom-space {
    .ant-tabs-nav-container {
      margin-bottom: 15px;
      margin-left: -10px;
    }
  }

  &.todo-tabs {
    .ant-tabs-nav-container {
      margin-top: 10px;
      margin-bottom: 15px;

      .ant-tabs-nav-wrap {
        padding-left: 0;

        .ant-tabs-tab {
          margin-right: 5px;
          padding-right: 20px;
          padding-left: 15px;

          &.ant-tabs-tab-active {
            &:before {
              bottom: 0!important;
            }
          }
        }
      }
    }
  }
}
