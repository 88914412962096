@import "./../global/variable";

.ant-picker {
  width: 100%;

  @media screen and (max-width:480px) {
    width: 40px;

  }
}

.ant-select-focused {
  .ant-select-selector {
    box-shadow: none!important;
    border-color: #16a085!important;
  }

  &.ant-select {
    .ant-select-selector {
      border-color: #16a085!important;
    }
  }
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #16a085!important;
}

.date-range-container {
  .custom-dropdown-width {
    width: 275px!important;
  }

  .ant-select-selection-placeholder {
    padding-left: 20px!important;
  }
}

.ant-picker {
  &:hover {
    border-color: #16a085!important;
  }
}

.ant-picker-focused {
  border-color: #16a085!important;
  box-shadow: none!important;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #5c5f66;
}


pre {
  overflow: hidden;
  line-height: 14px;
  font-weight: 400;
  font-size: 12px;
  white-space: pre-wrap;       /* Since CSS 2.1 */
  white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
  white-space: -pre-wrap;      /* Opera 4-6 */
  white-space: -o-pre-wrap;    /* Opera 7 */
  word-wrap: break-word;
  font-family:  sans-serif;
}

.ant-pagination {
  &.mini {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &.mt10 {
    margin-top: 10px;
  }
}


textarea{
  border-color: #d4d4d4;
  min-height: 50px;
}

.ant-select-selection-placeholder{
  display: flex;
  align-items: center ;
}

.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder{
  line-height: 38px;
}
.ant-select-show-search.ant-select-single:not(.ant-select-customize-input) .ant-select-selector input{
  height: 38px;
}

.apexcharts-legend-series{
  width: 43%;
}