@import '~antd/dist/antd.css';
@import '../common.scss';

input::placeholder {
  font-size: 13px;
  color: #dfdde9;
}

.input-btn {
  font-size: 17px;
  border-color:   $green-btn-border;
  background-color: $green-btn-bg;
  width: 100%;
}

.ant-select-dropdown {
  text-align: left;
  .ant-divider-horizontal {
    display: none;
  }

  .ant-select-dropdown-menu {
    padding-top: 0;
    padding-bottom: 0;

    .ant-select-dropdown-menu-item {
      &:last-child {
        margin-bottom: -5px;
      }
    }

    .ant-select-dropdown-menu-item-active {
      background-color: #16a085!important;
    }
  }
}

//.custom-dropdown-option{
//  display: inline-block;
//  width: 100%;
//  word-break: break-all;
//
//  .fa-trash {
//    position: absolute;
//    top:4px;
//    right: 10px;
//  }
//}

.custom-dropdown-width {
  width: 100%;

}
.add-new {
  background-color: #c5c8ce;;
}

.action-delete{
  cursor: pointer;
}

.ant-select-dropdown-menu-item {
  word-wrap: break-word;
  white-space: inherit;

  .fa-trash {
    display: none;
  }

  &:hover {
    background-color: #16a085!important;
    color: #fff;

    .fa-trash {
      display: inline-block;
      opacity: .7;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.ant-select-selection-selected-value {
  .anticon-delete {
    &.delete-icon {
      display: none;
    }
  }
}

.padding-top-7 {
  padding-top: 7px
}

.ag-cell-not-inline-editing {
  position: absolute!important;
  z-index: 9;
}

.ag-react-container {
  position: relative;

  .editable-cell-textarea {
    line-height: 1.5;
    min-height: 30px;
    padding-top: 3px;
    padding-left: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    resize: none;
    z-index: 99;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 2px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(#7580a0, .5);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
  }
}

.cell-dropdown {
  // margin-top: -25px;
  // padding-top: 20px;
  vertical-align: middle;

  &.ant-select-multiple {
    vertical-align: unset;
  }
}

.custom-dropdown-grid {
  .ant-select-selection__placeholder {
    line-height: 1;
  }

  .ant-select-selection-selected-value {
    line-height: 1.8;
  }

  .ant-select-arrow-icon {
    margin-top: -2px;
  }
}

.ant-select-selection-selected-value .fa-trash{
  display: none;
}




