.dashboard-wrapper {

  padding: 20px;
  background-color: #ffffff;
  
  // &.view-container {
  //   height: unset;
  //   // background-color: #F6F8FF;
  //   background-color: transparent;

  // }

  .inner-view {
      // margin-bottom: 10px;
      border: 1px solid #e7e7e7;
      height: 355px;
      background-color: #ffffff;
      border-radius: 7px;
      overflow: auto;

   
      // width: calc(50% - 30px);
      
.fees-progress{
  // width: 139px;
  // display: flex;
  // flex-direction: row;
  // padding: 10px 0px 10px 1px;

  display: flex;
  align-items: center;

.progress-bar{
  width: 56px;
  height: 56px;
  margin: 22px;
}

  .fees1{
    font-size: 12px;
    // padding: 5px;
  }

}


      .heading-title {
        display: inline-block;
        font-size: 18px;
        padding-top: 30px;
        padding-left: 55px;
        padding-bottom: 20px;
      }

      .title {
        color: #000;
        font-size: 14px;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 10px;
      }

      .table-caption {
        padding: 10px 0px 10px 15px;
        font-size: 14px;
        color: #58596C;
        font-weight: 600;
        // border-bottom: 1px solid #e7e7e7;
      }

      // .data-box{
      //   height: 363px;
      //   overflow: auto;
      //   overflow-x: auto;
      //   overflow-y: auto;
      // }

      .grid-data-container {
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 30px;

        table {
          width: 100%;

          thead {
            tr {
              background-color: #f3f3f3;
              border-bottom: 2px solid #e9ecf0;
              height: 30px;

              th {
                color: #39414b;

                &:first-child {
                  padding-left: 10px;
                }
              }
            }
          }

          tbody {
            tr {
              height: 30px;
              td {
                border-bottom: 1px solid #e9ecf0;

                &:first-child {
                  padding-left: 10px;
                }
              }
            }
          }
        }
      }

      &.tabular-data {
        height: 440px;
        overflow-y: auto;
      }
    }

  .summary-details {
    background-color: #FBFBFB;
    padding-top: 20px;

    .info {
      display: inline-block;
      text-align: center;
      width: 75%;

      .symbol {
        font-size: 20px;
        font-weight: 400;
      }

      .value {
        font-size: 19px;
      }

      .timeline {
        color: #a2a7b1;
        display: inline-block;
        font-size: 13px;
      }
    }
  }
}

.dashboard-top-layer{
  height: 40px;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-content: center;

  .greet{
    font-weight: 700;
    padding-top: 8px;
    font-size: 18px;
  }
}


.todo-container {
  background: #fff;
  border-radius: 4px;
  margin-top: 10px;
  padding-bottom: 20px;
  padding-right: 15px;
  padding-left: 15px;
  position: relative;

  .task-list {
    list-style-type: none;

    li {
      align-items: flex-end;
      background-color: #FBFBFB;
      border-bottom: 1px solid #ecebeb;
      color: #656b79;
      display: flex;
      padding: 10px;

      .undo-btn {
        background-color: #e9ecf0;
        border: 0;
        color: #656b79;
        cursor: pointer;
        display: none;
        padding-right: 5px;
        padding-left: 5px;
        position: absolute;
        right: 20px;
      }

      span {
        &:first-child {
          border-radius: 3px;
          color: #fff;
          display: inline-block;
          margin-right: 10px;
          margin-left: 10px;
          padding: 0 10px;
          text-transform: capitalize;
        }
      }

      .danger {
        background-color: #d0281c;
      }

      .success {
        background-color: #004d00;
      }

      .warning {
        background-color: #c36424;
      }

      .info {
        background-color: #395565;
      }

      .primary {
        background-color: #2f6af1;
      }

      .task-action {
        background-color: #E9ECF0;
        border-radius: 4px;
        display: none;
        position: absolute;
        right: 20px;

        i {
          cursor: pointer;
          margin-right: 5px;
          margin-left: 5px;
          padding-left: 5px;
          padding-right: 5px;
          width: 30px;
        }
      }

      &:hover {
        .task-action {
          display: inline-block;
        }

        .undo-btn {
          display: inline-block;
        }
      }

      .cancel-btn {
        align-items: center;
        background-color: #acb0b5;
        border-radius: 4px;
        color: #3e3e3e;
        cursor: pointer;
        display: inline-flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        width: 32px;
      }

      .save-btn {
        align-items: center;
        background-color: #2c7045;
        border-radius: 4px;
        color: #fff;
        cursor: pointer;
        display: inline-flex;
        font-size: 16px;
        height: 32px;
        justify-content: center;
        width: 32px;
      }

      &:first-child {
        border-top: 1px solid #ecebeb;
      }
    }
  }

  .add-task-btn {
    background-color: #4697CE;
    border: 0;
    color: #fff;
    cursor: pointer;
    border-radius: 3px;
    height: 32px;
    margin-left: 20px;
    padding-left: 10px;
    padding-right: 10px;
    position: absolute;
    top: 13px;
    left: 360px;

    &:focus {
      outline: 0;
    }
  }

  .form-action-control {
    display: flex;
    align-items: flex-end;

    input {
      margin-right: 10px;
    }
  }
}

.tiles-dashboard{
  margin: 20px 20px 0 20px;
  background-color: #EFF2FE;
}

.row-data {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .date-range-container {
    display: inline-flex;
    margin-right: 20px;
    position: relative;

    .fa-calendar {
      font-size: 14px;
      line-height: 1.25;
      position: absolute;
      top: 2px;
      left: 10px;
    }

    .custom-dropdown-width {
      width: 260px;

      .ant-select-selection {
        background-color: #E9ECF0;
        padding-left: 25px;

        .ant-select-selection__placeholder {
          color: #3d414a;
        }

        &:hover {
          background-color: #c4c7cb;
        }
      }
    }
  }
}

.count {
  align-items: center;
  background-color: #4697CE;
  border-radius: 50%;
  color: #fff!important;
  display: inline-flex;
  height: 18px;
  justify-content: center;
  margin-left: 10px;
  width: 18px;
  text-align: center;

  &.done {
    background-color: #7DCC93;
  }
}

.apexcharts-menu-icon{
  display: none;
}

.dropdown-button-calender{
  margin-right: 10px;
  &:hover{
    color: #000;
  }
}

.default-image-top-transaction{
  width:100%;
  height: 360px;
}


.new-checks { padding-top: 20px;}
.new-checks .checkbox:checked ~ .background-color{
	background-color: #fff;
}
.new-checks span{ font-size: 13px; font-weight: 400; padding-top: 5px; line-height: 18px; display: block;}

.new-checks [type="checkbox"]:checked,
.new-checks [type="checkbox"]:not(:checked),
.new-checks [type="radio"]:checked,
.new-checks [type="radio"]:not(:checked){
	position: absolute;
	left: -9999px;
	width: 0;
	height: 0;
	visibility: hidden;
}
.new-checks .checkbox:checked + label,
.new-checks .checkbox:not(:checked) + label{
	position: relative;
	width: 70px;
	display: inline-block;
	padding: 0;
	margin: 0 auto;
	text-align: center;
	margin: 17px 0;
	margin-top: 100px;
	height: 6px;
	border-radius: 4px;
	// background-image: linear-gradient(298deg, var(#4ca185), var(#fff));
  background: linear-gradient(#ffffff, #dddddd); 
	z-index: 100 !important;
}
.new-checks .checkbox:checked + label:before,
.new-checks .checkbox:not(:checked) + label:before {
	position: absolute;
	font-family: 'unicons';
	cursor: pointer;
	top: -17px;
	z-index: 2;
	font-size: 18px;
	line-height: 40px;
	text-align: center;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
}
.new-checks .checkbox:not(:checked) + label:before {
	content: '\eac1';
	left: 0;
	color: #000;
  background: linear-gradient(#ffffff, #e7e7e7); 
	// background-color: #f1f1f1;
	box-shadow: 0 4px 4px rgba(0,0,0,0.15), 0 0 0 1px rgba(26,53,71,0.07);
}
.new-checks .checkbox:checked + label:before {
	content: '\eb8f';
	left: 30px;
	color: #000;
	background-color: #fff;
	box-shadow: 0 4px 4px rgba(26,53,71,0.25), 0 0 0 1px rgba(26,53,71,0.07);
}

.new-checks .checkbox:checked ~ .section .container .row .col-12 p{
	color: var(--dark-blue);
}


.new-checks .checkbox-toolss:checked + label,
.new-checks .checkbox-toolss:not(:checked) + label{
	position: relative;
	display: inline-block;
	padding: 15px 10px 15px 70px;
	width: 95% !important;
	font-size: 20px;
	line-height: 20px;
	letter-spacing: 1px;
	margin: 0 auto; font-weight: 400;
	margin-left: 0px;
	margin-right: 0px;
	margin-bottom: 25px;
	text-align: center;
	border-radius: 4px;
	overflow: hidden;
	cursor: pointer;
	// text-transform: uppercase;
	color: #000;
	-webkit-transition: all 300ms linear;
	transition: all 300ms linear; 
  min-height: 70px;
}
.new-checks .checkbox-toolss:not(:checked) + label{
	// background-color: #f9f9f9;
  // 
  // background: #38bb90;
	// box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  background: rgb(235, 235, 235);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.new-checks .checkbox-toolss:checked + label{
	background-color: transparent;
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    // color: #fff !important; background: #38bb90;
    color: rgb(63, 62, 62) !important; background: #fff;
}
.new-checks .checkbox-toolss:not(:checked) + label:hover{
	box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.new-checks .checkbox-toolss:checked + label::before,
.new-checks .checkbox-toolss:not(:checked) + label::before{
	position: absolute;
	content: '';
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-radius: 4px;
	/* background-image: linear-gradient(298deg, var(#4ca185), var(#51a35d)); */
    background: linear-gradient(#ffffff, #d8d8d8); 
	z-index: -1;
}
.new-checks .checkbox-toolss:checked + label .uil,
.new-checks .checkbox-toolss:not(:checked) + label .uil{
	font-size: 20px;
	line-height: 24px;
	display: block;
	padding-bottom: 10px; color: #000;
}
.cltys1{ position: absolute; left: 10px; top: 20px; font-size: 40px;}

.new-checks .dontaion{background: url(../../assets/images/donationh-icons.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .dontaion {background: url(../../assets/images/donationh-icons.png) no-repeat center top;
    background-size: 100%; 
}


.new-checks .income-ex{background: url(../../assets/images/income-exh.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .income-ex {background: url(../../assets/images/income-exh.png) no-repeat center top;
    background-size: 100%; 
}
.new-checks .balance-sheet{background: url(../../assets/images/balance-sheeth.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .balance-sheet {background: url(../../assets/images/balance-sheeth.png) no-repeat center top;
    background-size: 100%; 
}


.new-checks .pl-icon{background: url(../../assets/images/plh-icon.png) no-repeat center top;
  background-size: 100%; width: 45px; height: 60px;
}
.new-checks .checkbox-toolss:checked + label .pl-icon {background: url(../../assets/images/plh-icon.png) no-repeat center top;
    background-size: 100%; 
}

.libe-box{ width:100%; float: right; background: #797878; height:1px; margin: 8px 0px 8px 0%; } 
.new-checks .checkbox-toolss:checked + label .libe-box{  background: #1f1e1e; } 
.lafr{ font-size: 18px !important;}


.account-integsmd{ margin:10px;  
  position: relative; 
border: 1px  solid #f0f0f0 !important; 
-webkit-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
-moz-box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
box-shadow: 0px 7px 30px 0px rgba(235,235,235,1);
background: #fff; width:auto;
border-radius: 6px; min-height: 300px;
}
.account-integsmd p{ padding: 5px 10px 5px 20px !important;}
.top-box-hedd{ position: relative; 
height: 60px; 
border-bottom: solid 1px #f0f0f0;
padding: 10px 20px 5px; font-size: 20px; font-weight: 600;
}


.sudlie-text{ 
  position: relative;

 .mea-text{ padding: 0px 20px ;
    position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(0%, 0%);
    font-size: 18px;
    color: #888;

    a{ color: #0056ff;}
  }
     
     img { display: inline-flex; text-align: center;
      margin-top: 60px;
    
    } 
  
}

.list{
  display: flex;
  flex-direction: row;
  list-style-type: none;
gap: 39px;
padding: 16px 0px 0px 16px;
font-size: 12px;
font-weight: 400

;
color: #031639;
}
.list-font{
  font-size: 22px;
  font-weight: 500;
}



.inner-view-as{
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 10px; 

.inner-view1{
  border: 1px solid #e7e7e7;
  height: 293px;
  background-color: #ffffff;
  border-radius: 7px;
  width: 50%;
  overflow: hidden;

  @media screen and (max-width:480px) {
    overflow: auto;

    ::-webkit-scrollbar {
      display: none;
    }
  }
}
}
.progress-chart{

  .apexcharts-canvas{
    height: 200px;
  }
  .apexcharts-legend.apx-legend-position-right, .apexcharts-legend.apx-legend-position-left{
    flex-direction: column;
    width: 180px;
  }

}

.card-holder{
  overflow: auto;
}
.tile-data{
  overflow-x: auto;
  overflow-y: hidden;
}

.tile-data::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width:480px) {
  .inner-view1::-webkit-scrollbar {
    display: none;
  }
}

.inner-view1{
  border: 1px solid #e7e7e7;
  height: 293px;
  background-color: #ffffff;
  border-radius: 7px;
  width: 50%;
  overflow: auto;
}
.inner-view1::-webkit-scrollbar {
  display: none;
}