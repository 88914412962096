.change-password-container {
  height: 100vh;

  .profile-pages-heading {
    height: 70px;
    border-radius: 4px 4px 0px 0px;
    border-bottom: 1px solid #e8e8ff;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0 30px;
    .page-heading {
      color: #08173d;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 0;
    }
  }

  .page-content {
    padding: 30px;

    .footer-button-group {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
}
