.ant-btn {
  &:hover, &:focus, &:active {
    border-color: transparent;
  }
}

.font-500 {
  color: #000;
  font-weight: 500;
}

.light-font {
  color: #717171;
  font-size: 12px;
  line-height: 1.5;
}
